import React from 'react';
import Helmet from "react-helmet";

import { ThemeContext } from '../../context/theme-context';

import Layout from '../../components/layout';
import Footer from '../../components/footer';
import Title from '../../components/title';
import BannerImage from '../../components/bannerImage';
import ContactBlock from '../../components/contactBlock';
import NextCase from '../../components/nextCase';
import IntroBlock from '../../components/introBlock';
import BannerResult from '../../components/bannerResult';
import CarouselImage from '../../components/carouselImage';
import SimplePhrase from '../../components/simplePhrase';
import ChromaticPalette from '../../components/chromaticPalette';

import imgData from "../../data/principal-papelaria.json";

const CasesPage = ({ location }) => {

    return (
      <Layout bg="white" location={location}>
        <Helmet>
          <title>ATUO - Cases - Principal Papelaria</title>
        </Helmet>

        <div className="container mx-auto">
          <Title
            title="Principal <br /> Papelaria"
            titleColor="#FF0000"
            description="Uma papelaria criativa e diferente, agora digital"
            smallDescription="[criativa, divertida, conectada]"
          />

          <BannerImage
            src={`/principal/principal_content_one.jpg`}
            title="Principal Papelaria"
          />

          <IntroBlock
            logo={`/principal/principal_logo.svg`}
            alt="Principal Papelaria"
            description="Nascida em 2001, a Principal Papelaria ficou desposicionada no mercado e passou a sofrer com a chegada de novos concorrentes. Era preciso fortalecer seu posicionamento e inovar a marca para se adequar à nova realidade do segmento em que atua."
            list={[
              "Estratégia & Inovacão",
              "Pesquisa & Inteligência",
              "Design de Experiência do Consumidor",
            ]}
          />

          <SimplePhrase>
            Adequar o posicionamento, transformando-a em uma marca moderna e
            competitiva no meio digital.
          </SimplePhrase>

          <div className="flex justify-center gap-x-8 md:px-40 items-center mt-24 md:my-40 flex-col md:flex-row">
            <div className="md:w-1/2 text-xl order-2 md:order-1 mt-10 mb-24 md:my-0">
              <h3 className="text-4xl font-semibold leading-tight mt-24 mb-10 md:mt-0">
                A marca Principal Papelaria é Digital First!
              </h3>
              <p>
                Para mostrar que a Principal Papelaria agora é digital, o
                conceito do logo foi recriado para ser moderno, com linhas
                suaves. Reforçando o segmento, além de apresentar o papel como
                elemento principal, ele também foi reforçado em suas curvas para
                formar a letra P.
              </p>
            </div>

            <div className="md:w-1/2 order-1 md:order-2 text-center">
              <img
                src={`/principal/principal_stamp.svg`}
                alt="Principal"
                className="inline"
              />
            </div>
          </div>

          <ChromaticPalette
            description={
              "Os tons foram inspirados nas cores primárias e nos arquétipos de Jung, com cores harmônicas e simbologia clara e aplicável em todos os meios."
            }
          >
            <div className="py-24 md:py-16 flex flex-wrap justify-center gap-y-10 gap-x-9 md:gap-x-16">
              <div
                className="cardColorBlock"
                data-aos="fade-up"
                data-aos-delay="0"
                style={{ backgroundColor: "#ff0000" }}
              >
                #ffa800
              </div>
              <div
                className="cardColorBlock"
                data-aos="fade-up"
                data-aos-delay="300"
                style={{ backgroundColor: "#feee00", color: "#000" }}
              >
                #feee00
              </div>
              <div
                className="cardColorBlock"
                data-aos="fade-up"
                data-aos-delay="600"
                style={{ backgroundColor: "#0099ff" }}
              >
                #0099ff
              </div>
              <div
                className="cardColorBlock"
                data-aos="fade-up"
                data-aos-delay="900"
                style={{ backgroundColor: "#000000" }}
              >
                #000000
              </div>
            </div>
          </ChromaticPalette>

          <div className="grid grid-cols-1 md:grid-cols-2 my-40">
            <div className="w-full md:w-4/5">
              <h3 className="text-2xl md:text-4xl mb-24 md:mb-10 font-semibold md:leading-tight">
                Guia de Expressão da Marca
              </h3>

              <p className="text-2xl hidden md:block">
                O guia da marca contempla tom de voz, uso e composição de cores,
                tipografia, logo e demais estratégias de comunicação e aplicação
                da marca. <br />
                <br />
                Se utilizando do design thinking, o visual foi reformulado de
                forma estratégica, que reforça a comunicação da marca.
              </p>
            </div>

            <div className="mb-10 md:mb-0 flex justify-center items-center">
              <img
                src={`/principal/principal_content_two.png`}
                alt="Papelaria Principal"
              />
            </div>

            <p className="text-2xl block md:hidden">
              O guia da marca contempla tom de voz, uso e composição de cores,
              tipografia, logo e demais estratégias de comunicação e aplicação
              da marca. <br />
              <br />
              Se utilizando do design thinking, o visual foi reformulado de
              forma estratégica, que reforça a comunicação da marca.
            </p>
          </div>

          <div className="flex flex-col md:flex-row mt-4 md:mt-40 gap-y-10 md:gap-0">
            <div data-aos="fade-right">
              <img
                src={`/principal/principal_content_three.jpg`}
                alt="Papelaria Principal"
              />
            </div>

            <div className="flex flex-col md:flex-col gap-y-10 md:gap-0">
              <div data-aos="fade-left" data-aos-delay="400">
                <img
                  src={`/principal/principal_content_four.jpg`}
                  alt="Papelaria Principal"
                />
              </div>
              <div data-aos="fade-left" data-aos-delay="800">
                <img
                  src={`/principal/principal_content_five.jpg`}
                  alt="Papelaria Principal"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="my-40">
          <div className="container">
            <p className="text-2xl font-semibold md:leading-tight md:text-4xl md:text-center">
              A Principal Papelaria ganhou uma cara nova, alinhada com a era
              digital, com personalidade divertida e que conquista fãs em todos
              os lugares.
            </p>
            <p className="text-xl md:text-2xl mt-10 md:mt-24 md:text-center">
              O visual da loja segue o guide visual da marca, contando histórias
              reais e despertando sensações por meio de ambientes criativos com
              o uso das cores da papelaria criativa.
            </p>
          </div>

          <div className="container mt-20">
            <CarouselImage>
            {imgData.map((el) => (
                <div className="item">
                <img src={el.img} alt="Papelaria principal" />
                </div>
              ))};
            </CarouselImage>
          </div>

          <p className="container text-right my-10 text-xl">
            [Um ambiente criativo e convidativo onde o consumidor deseje estar]
          </p>
        </div>

        <BannerResult
          bg="#0099FF"
          list={[
            "Experiência de marca",
            "Definição clara de sua atuação no mercado",
            "Melhor experiência de compra",
            "Marca com propósito",
            "+ Presença digital",
            "Brand Awareness",
            "Aumento do ticket médio",
            "Definição objetiva do ambiente de compra",
          ]}
        />

        <NextCase
          link="/cases/mercado-de-terras"
          title="Mercado de Terras"
          description="O valor da terra brasileira por quem ama nosso chão."
          image="/mercado-de-terras-next.png"
        />

        <ThemeContext.Consumer>
          {(cx) => (
            <ContactBlock
              description="Gostou dos nossos cases? Tem muito mais de onde veio! Clique no botão ao lado e entre em contato conosco. Vamos dar o primeiro passo para sua marca ir além."
              weather={cx?.cookies?.weather}
            />
          )}
        </ThemeContext.Consumer>

        <Footer />
      </Layout>
    );
};

export default CasesPage;
