import React, { useEffect, useRef } from "react";

const CarouselImage = ({ children }) => {

    const container = useRef(null);
    const wrapper = useRef(null);

    useEffect(() => {
        const imgsWidth = Array.from(container.current.querySelectorAll(".item")).map((el) => el.clientWidth);
        const sumWithInitial = imgsWidth.reduce(
            (acc, cur) => acc + cur
        );

        container.current.style.width = `${sumWithInitial}px`;
        wrapper.current.style.display = "flex";

    }, [])

    return (
        <div className="w-screen over-wrapper gallery" ref={container}>
            <div className="wrapper" ref={wrapper}>
                {children}
            </div>
        </div>
    )
};

export default CarouselImage;
